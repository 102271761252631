import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  cta?: ButtonProps
  description?: string
  images: ImageProps[]
  languageCode: string
  menu?: string
  sectionID?: string
  subtitle?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  cta,
  description,
  images,
  languageCode,
  menu,
  sectionID,
  subtitle,
  title,
}: Props) {
  const [lineRef, isLineVisible] = useInView({ threshold: 0.25 })
  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    defaultAnimation: {
      duration: 2000,
    },
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container id={`section-${sectionID}`} dial={4} row tag="section" wrap>
      <LeftSide>
        <Line ref={lineRef} className={isLineVisible ? 'visible' : undefined} />

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}

        {cta || menu ? (
          <Buttons row wrap>
            {menu ? (
              <FadeInUp>
                <CTA
                  label={useVocabularyData('browse-the-menu', languageCode)}
                  file={menu}
                />
              </FadeInUp>
            ) : null}

            {cta ? (
              <FadeInUp>
                <CTA {...cta} />
              </FadeInUp>
            ) : null}
          </Buttons>
        ) : null}
      </LeftSide>

      <RightSide>
        <Slider ref={sliderRef}>
          {uniq(images).map((item, index) => (
            <Slide className="keen-slider__slide" key={index}>
              <Inner style={positionStyle(index)}>
                {loaded[index] ? (
                  <Image
                    {...item}
                    alt={loaded[index] ? item.alt : undefined}
                    media="(min-width: 2800px)"
                  />
                ) : (
                  <Spinner />
                )}
              </Inner>
            </Slide>
          ))}
        </Slider>

        {images.length > 1 ? (
          <Arrows row>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
            />
            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
            />
          </Arrows>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 12.5rem 0;

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
  }

  @media (max-width: 1023px) {
    margin: 5.625rem 0;
    padding: 0 1.3125rem;
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding: 1.875rem 7.361vw 0 9.722vw;
  position: relative;
  transform: translateY(-2.8125rem);

  @media (max-width: 1199px) {
    padding-right: 3.75rem;
    padding-left: 1.3125rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    padding: 1.875rem 0 0;
    transform: none;
  }
`

const Line = styled.div`
  height: 0.0625rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  position: absolute;
  top: 0;
  right: 100%;
  left: 9.722vw;
  z-index: 2;
  transition: 0.6s ease-in-out;
  &.visible {
    right: -6.25vw;
  }

  @media (max-width: 1199px) {
    left: 1.3125rem;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  line-height: 4.375rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.875rem;
  }
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.0938rem;
  line-height: 1.125rem;
  margin-top: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    margin-top: 0.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 3.75rem;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }
`

const Buttons = styled(FlexBox)`
  > div {
    &:nth-of-type(2) {
      a {
        margin-left: 1.5rem;
      }
    }
  }

  @media (max-width: 767px) {
    display: block;

    > div {
      &:nth-of-type(2) {
        a {
          margin: 1.5rem 0 0 0;
        }
      }
    }
  }
`

const CTA = styled(Button)``

const RightSide = styled.div`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
    position: relative;
  }
`

const Slider = styled.div`
  display: flex;
  height: 66.7vh;
  max-height: 37.5rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  overflow: hidden;
  position: relative;

  @media (max-width: 1023px) {
    max-height: 28.125rem;
  }

  @media (max-width: 767px) {
    max-height: 21.875rem;
  }
`

const Slide = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Arrows = styled(FlexBox)`
  margin-top: 1.875rem;

  @media (max-width: 1023px) {
    margin-top: 0;
    position: absolute;
    bottom: 1.875rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    > div {
      border-color: ${({ theme }) => theme.colors.variants.neutralLight4};

      svg {
        stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
      }
    }
  }
`
