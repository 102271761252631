import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ArrowLeft, ArrowRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  direction?: 'L' | 'R'
  onClick?: (e: any) => void
  variant?: Variant
}

export const Arrow = memo(function Arrow({
  direction = 'L',
  onClick,
  variant = 'default',
}: Props) {
  return (
    <Container dial={5} onClick={onClick} variant={variant}>
      {direction === 'L' ? <ArrowLeft /> : <ArrowRight />}
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  width: 3.75rem;
  height: 3.75rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralDark3};
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0.9375rem;
  position: relative;
  transition: 0.3s ease-in-out;
  &:first-of-type {
    margin-left: 0;
    &:before {
      transform-origin: right center;
    }
  }
  &:before {
    content: '';
    width: 103%;
    height: 103%;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    top: -2%;
    left: -1%;
    transform: scale(0);
    transform-origin: left center;
    transition: 0.3s ease-in-out;
  }
  &:hover {
    &:before {
      opacity: 1;
      transform: scale(1);
    }
    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }

  svg {
    width: auto;
    height: 1rem;
    fill: none;
    position: relative;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark3};
    stroke-width: 1;
    transition: 0.2s ease-in-out;
  }

  ${({ theme, variant }) => {
    switch (variant) {
      case 'invert':
        return css`
          border-color: ${theme.colors.variants.neutralLight4};
          &:before {
            background: ${theme.colors.variants.neutralLight4};
          }
          &:hover {
            svg {
              stroke: ${theme.colors.variants.neutralDark3};
            }
          }

          svg {
            stroke: ${theme.colors.variants.neutralLight4};
          }
        `
    }
  }}

  @media (max-width: 1023px) {
    width: 3.125rem;
    height: 3.125rem;
    margin-left: 0.5625rem;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'invert'
