import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  description?: string
  images: ImageProps[]
  subtitle?: string
  title?: string
}

export const Visual = memo(function Visual({
  description,
  images,
  subtitle,
  title,
}: Props) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    defaultAnimation: {
      duration: 2000,
    },
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container id="section-visual">
      <Gallery>
        <Slider ref={sliderRef}>
          {uniq(images).map((item, index) => (
            <Slide className="keen-slider__slide" key={index}>
              <Inner style={positionStyle(index)}>
                {loaded[index] ? (
                  <Image
                    {...item}
                    alt={loaded[index] ? item.alt : undefined}
                    media="(min-width: 2800px)"
                  />
                ) : (
                  <Spinner />
                )}
              </Inner>
            </Slide>
          ))}
        </Slider>

        {images.length > 1 ? (
          <Arrows row>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              variant="invert"
            />
            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              variant="invert"
            />
          </Arrows>
        ) : null}
      </Gallery>

      <Wrapper row wrap>
        <LeftSide>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}

          {subtitle ? (
            <FadeInUp>
              <Subtitle>{subtitle}</Subtitle>
            </FadeInUp>
          ) : null}
        </LeftSide>

        <RightSide>
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem 0;

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
  }
`

const Gallery = styled.div`
  position: relative;
  &:after {
    content: '';
    width: 0.0625rem;
    height: 5rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  @media (max-width: 1199px) {
    &:after {
      height: 2.5rem;
    }
  }

  @media (max-width: 1023px) {
    &:after {
      display: none;
    }
  }
`

const Slider = styled.div`
  display: flex;
  height: calc(100vh - 5.625rem);
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  overflow: hidden;
  position: relative;

  @media (max-width: 1199px) {
    height: calc(100vh - 7.5rem);
  }
`

const Slide = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 5rem;
  right: 16.528vw;
  z-index: 2;

  @media (max-width: 1199px) {
    bottom: 1.875rem;
    right: 1.3125rem;
  }

  @media (max-width: 1023px) {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Wrapper = styled(FlexBox)`
  padding: 4.375rem 0;
  &:after {
    content: '';
    width: 0.0625rem;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  @media (max-width: 1199px) {
    padding: 3.125rem 0;
  }

  @media (max-width: 1023px) {
    padding: 1.875rem 1.3125rem 0;
    text-align: center;

    &:after {
      display: none;
    }
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding: 0 4.653vw 0 16.528vw;

  @media (max-width: 1199px) {
    padding: 0 2.5rem 0 1.3125rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  line-height: 4.375rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.875rem;
  }
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.0938rem;
  line-height: 1.125rem;
  margin-top: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    margin-top: 0.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.875rem;
`

const RightSide = styled.div`
  width: 50%;
  padding: 0 16.528vw 0 4.653vw;

  @media (max-width: 1199px) {
    padding: 0 1.3125rem 0 2.5rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 1.875rem;
    padding: 0;
  }
`
