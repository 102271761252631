import * as React from 'react'
import { memo } from 'react'

const SvgArrowLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 15 15.413"
  >
    <path d="M14.5 7.74H.571M7.962.707l-7.462 7 7.462 7" />
  </svg>
)

const Memo = memo(SvgArrowLeft)
export default Memo
