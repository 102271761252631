import styled from '@emotion/styled'
import { Brand } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

import { Item } from './Item'

export interface Props {
  description?: string
  languageCode: string
  list?: string[]
  phone?: string
}

export const Info = memo(function Info({
  description,
  languageCode,
  list,
  phone,
}: Props) {
  if (!list) {
    return null
  }

  return (
    <Container id="section-info">
      <Brand />

      <Label>{useVocabularyData('reserve', languageCode)}</Label>

      <Title>{useVocabularyData('info-contacts', languageCode)}</Title>

      <Wrapper dial={2} row wrap>
        {list.map((item: any, index) => (
          <Item key={index} time={item.time} title={item.title} />
        ))}
      </Wrapper>

      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}

      {phone ? (
        <ButtonWrap dial={5} row>
          <CTA
            href={`tel:${phone}`}
            onClick={() => {
              if (isMobile) {
                typeof window.gtag !== 'undefined' &&
                  window.gtag('event', 'Click', {
                    event_category: 'Website',
                    event_label: 'Phone Number',
                  })
              }
            }}
            rel="noopener"
            target="_blank"
          >
            <CTALabel>
              {useVocabularyData('book-a-table', languageCode)}
            </CTALabel>
            <CTALabel>{phone}</CTALabel>
            <CTALabel>{useVocabularyData('option-1', languageCode)}</CTALabel>
          </CTA>
        </ButtonWrap>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  padding: 12.5rem 0 2.75rem;
  position: relative;
  text-align: center;

  > svg {
    width: auto;
    height: 3.75rem;
    margin-bottom: 1.875rem;
  }

  @media (max-width: 1199px) {
    padding: 7.5rem 0 1.875rem;

    > svg {
      margin-bottom: 0.75rem;
    }
  }

  @media (max-width: 767px) {
    padding-top: 5.625rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 8.125rem;
  line-height: 10.875rem;

  @media (max-width: 1199px) {
    font-size: 5.625rem;
    line-height: 7.5rem;
  }

  @media (max-width: 767px) {
    font-size: 3.75rem;
    line-height: 5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.0938rem;
  line-height: 1.125rem;
  transform: translateY(-4.6875rem);
  text-transform: uppercase;

  @media (max-width: 1199px) {
    transform: translateY(-3.4375rem);
  }

  @media (max-width: 767px) {
    transform: translateY(-2.5rem);
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 48rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: -3.4375rem auto 0;
  padding: 5rem 0;

  @media (max-width: 1199px) {
    width: calc(100vw - 2.625rem);
    max-width: none;
    margin-top: -2.6875rem;
    padding: 3.125rem 0;
  }

  @media (max-width: 767px) {
    margin-top: -2.1875rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  margin-top: 1.875rem;
`

const ButtonWrap = styled(FlexBox)`
  margin-top: 3.3125rem;

  &:before,
  &:after {
    content: '';
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    flex: 1;
  }
`

const CTA = styled.a`
  width: 23.5rem;
  border: 1px solid ${({ theme }) => theme.colors.variants.neutralDark3};
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  letter-spacing: 0.0437rem;
  line-height: 1rem;
  padding: 1.25rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    letter-spacing: 0.0938rem;
  }

  @media (max-width: 767px) {
    width: calc(100vw - 2.625rem);
  }
`

const CTALabel = styled.span`
  display: block;
  &:nth-of-type(2) {
    font-weight: 600;
  }
`
