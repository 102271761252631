import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Close } from 'app/components/Common/Modal/Close'
import { Plus } from 'app/components/Icons'
import { theme } from 'app/theme'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Masonry from 'react-masonry-component'

import { Gallery } from './Gallery'

export interface Props {
  images: ImageProps[]
  languageCode: string
  siteName?: string
  thumbnails?: ImageProps[]
}

export const Mosaic = memo(function Mosaic({
  images,
  languageCode,
  siteName,
  thumbnails,
}: Props) {
  if (images?.length < 1) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(null)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      <Label>{useVocabularyData('gallery', languageCode)}</Label>

      {thumbnails ? (
        <Wrapper>
          <Thumbs
            options={{
              percentPosition: true,
              transitionDuration: 0,
            }}
          >
            {uniq(thumbnails)
              .slice(0, 12)
              .map((item, index) => (
                <Thumb
                  key={index}
                  onClick={() => {
                    if (!modalGalleryStatus) {
                      setLocked(true)
                      setModalGalleryStatus(true)
                    } else {
                      setLocked(false)
                      setModalGalleryStatus(false)
                    }

                    setClickedSlide(index)
                    setTimeout(() => {
                      setClickedSlide(null)
                    }, 100)
                  }}
                >
                  <LazyLoadComponent>
                    <Image {...item} />
                    {index === 11 ? (
                      <CTA>
                        <Plus />
                        <CTALabel>
                          {useVocabularyData('look-at-all', languageCode)}
                        </CTALabel>
                      </CTA>
                    ) : null}
                  </LazyLoadComponent>
                </Thumb>
              ))}
          </Thumbs>
        </Wrapper>
      ) : null}

      {images ? (
        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <Close
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
              setClickedSlide(null)
            }}
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
            siteName={siteName}
          />
        </Modal>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  padding: 11.625rem 5.556vw 12.5rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 7.5rem 1.3125rem 5.625rem;
  }

  @media (max-width: 767px) {
    padding-top: 3.75rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 8.125rem;
  line-height: 10.875rem;

  @media (max-width: 1199px) {
    font-size: 5.625rem;
    line-height: 8.125rem;
  }

  @media (max-width: 767px) {
    font-size: 3.75rem;
    line-height: 5rem;
  }
`

const Wrapper = styled.div`
  margin-top: -2.1875rem;
  padding-top: 1.0625rem;
  padding-bottom: 6.25rem;
  position: relative;
  &:before {
    content: '';
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 0;
    right: 12.917vw;
    left: 12.917vw;
  }

  @media (max-width: 767px) {
    margin-top: -1.125rem;
  }
`

const Thumbs = styled(Masonry)`
  margin-right: -5.1875rem;

  @media (max-width: 1199px) {
    margin-right: -1.3125rem;
  }

  @media (max-width: 767px) {
    margin-right: -0.25rem;
  }
`

const Thumb = styled.div`
  width: calc(33.333% - 5.1875rem);
  height: 18.472vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  cursor: pointer;
  margin: 5.1875rem 5.1875rem 0 0;
  overflow: hidden;
  position: relative;
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  &:nth-of-type(1),
  &:nth-of-type(3),
  &:nth-of-type(4),
  &:nth-of-type(7),
  &:nth-of-type(9),
  &:nth-of-type(10) {
    height: 35vw;
  }

  img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out !important;
  }

  @media (max-width: 1199px) {
    width: calc(33.333% - 1.3125rem);
    margin: 1.3125rem 1.3125rem 0 0;
  }

  @media (max-width: 767px) {
    width: calc(33.333% - 0.25rem);
    margin: 0.25rem 0.25rem 0 0;
  }
`

const CTA = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  backface-visibility: hidden;
  background: ${rgba(theme.colors.variants.neutralDark1, 0.4)};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  &:hover {
    svg {
      transform: none;
    }
    span {
      opacity: 1;
    }
  }

  svg {
    width: auto;
    height: 2.5rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    transform: translateY(1.75rem);
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1199px) {
    svg {
      transform: none;
    }
  }
`

const CTALabel = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  letter-spacing: 0.0437rem;
  line-height: 0.9375rem;
  opacity: 0;
  margin-top: 0.75rem;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
