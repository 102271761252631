import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Brand } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
}

export const Intro = memo(function Intro({ description, image, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container id="section-intro">
      <Brand />
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}

      {image ? (
        <Wrapper row space="between">
          <LazyLoadComponent>
            <Image {...image} />
            <Image {...image} />
            <Image {...image} />
          </LazyLoadComponent>
        </Wrapper>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  padding: 12.5rem 9.722vw 8.75rem;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: 0.0625rem;
    height: 8.75rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  > svg {
    width: auto;
    height: 4.375rem;
    margin-bottom: 3.75rem;
  }

  @media (max-width: 1199px) {
    padding: 3.75rem 1.3125rem 1.3125rem;
    &:before {
      display: none;
    }

    > svg {
      height: 3.125rem;
      margin-bottom: 2.5rem;
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.0938rem;
  line-height: 1.125rem;
  text-transform: uppercase;
`

const Description = styled.div`
  max-width: 60.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.25rem;
  line-height: 3.75rem;
  margin: 2.5rem auto 0;

  @media (max-width: 1199px) {
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin-top: 1.875rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-top: 6.25rem;

  picture {
    width: calc(33.333% - 3.8125rem);
    height: 66.7vh;
    max-height: 37.5rem;
    position: relative;
    &:first-of-type {
      img {
        object-position: left center;
      }
    }
    &:last-of-type {
      img {
        object-position: right center;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 1199px) {
    margin-top: 3.75rem;

    picture {
      width: calc(33.333% - 0.75rem);
      max-height: 28.125rem;
    }
  }

  @media (max-width: 1023px) {
    picture {
      max-height: 21.875rem;
    }
  }

  @media (max-width: 767px) {
    picture {
      width: calc(33.333% - 0.25rem);
      max-height: 12.5rem;
    }
  }
`
