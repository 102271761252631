import styled from '@emotion/styled'
import { Brand } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  time?: string
  title?: string
}

export const Item = memo(function Item({ time, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <Title>{title}</Title>

      {time ? <Time>{time}</Time> : null}
    </Container>
  )
})

const Container = styled.div`
  margin: 0 5.556vw;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.5rem;
  &:after {
    content: '';
    display: block;
    width: 0.0625rem;
    height: 3.75rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    margin: 0.75rem auto;
  }

  @media (max-width: 1199px) {
    &:after {
      height: 2.5rem;
    }
  }
`

const Time = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
`
