import * as React from 'react'
import { memo } from 'react'

const SvgArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 15 15.413"
  >
    <path d="M.5 7.74h13.929M7.038.707l7.462 7-7.462 7" />
  </svg>
)

const Memo = memo(SvgArrowRight)
export default Memo
