import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  claim?: string
  image?: ImageProps
  mobileImage?: ImageProps
  payoff?: string
}

export const Hero = memo(function Hero({
  claim,
  image,
  mobileImage,
  payoff,
}: Props) {
  if (!image) {
    return null
  }

  return (
    <>
      {mobileImage ? (
        <Media lessThan="ipadVertical">
          <Helmet>
            <link
              rel="preload"
              as="image"
              href={mobileImage.src}
              imageSrcSet={mobileImage.srcSet}
            />
          </Helmet>
        </Media>
      ) : null}

      <Container>
        <style type="text/css">{mediaStyle}</style>
        <MediaContextProvider>
          <Media lessThan="ipadVertical">
            <Picture decoding="async" {...mobileImage} />
          </Media>
          <Media greaterThanOrEqual="ipadVertical">
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          </Media>

          {claim || payoff ? (
            <Wrapper>
              {payoff ? <Payoff>{payoff}</Payoff> : null}
              {claim ? <Claim>{claim}</Claim> : null}
            </Wrapper>
          ) : null}
        </MediaContextProvider>
      </Container>
    </>
  )
})

const Container = styled.section`
  height: 100vh;
  max-height: -webkit-fill-available;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:after {
    content: '';
    width: 0.0625rem;
    height: 8.75rem;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @media (max-width: 1199px) {
    &:after {
      display: none;
    }
  }
`

const Picture = styled(Image)`
  width: 100%;
  height: calc(100% - 3.75rem);
  overflow: hidden;
  position: absolute;
  top: 3.75rem;
  left: 0;
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: absolute;
  top: 50%;
  right: 5.556vw;
  left: 5.556vw;
  text-align: center;
  transform: translateY(-50%);
  z-index: 3;

  @media (max-width: 1199px) {
    right: 1.3125rem;
    left: 1.3125rem;
  }
`

const Payoff = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  line-height: 4.375rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.875rem;
  }
`

const Claim = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.0938rem;
  line-height: 1.125rem;
  margin-top: 1.25rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    margin-top: 0.75rem;
  }
`
