import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  clickedSlide?: number
  images: ImageProps[]
  isOpen?: boolean
  siteName?: string
}

export const Gallery = memo(function Gallery({
  clickedSlide,
  images,
  isOpen,
  siteName,
}: Props) {
  if (!images) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1200,
    },
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
    breakpoints: {
      '(max-width: 767px)': {
        drag: true,
      },
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    if (clickedSlide !== undefined) {
      galleryRef.current?.moveToIdx(clickedSlide, false, { duration: 0 })
    }

    setLoaded(newLoaded)

    if (isOpen) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 37:
            galleryRef.current?.prev()
            break
          case 39:
            galleryRef.current?.next()
            break
        }
      })
    }
  }, [clickedSlide, currentSlide, galleryRef, sliderRef])

  return (
    <Container>
      {siteName ? <SiteName>{siteName}</SiteName> : null}

      <Carousel ref={sliderRef}>
        {uniq(images).map((item, index) => (
          <Slide key={index} className="keen-slider__slide">
            <Inner style={positionStyle(index)}>
              {loaded[index] ? (
                <Image media="(min-width: 2800px)" {...item} />
              ) : (
                <Spinner />
              )}
            </Inner>
          </Slide>
        ))}
      </Carousel>

      {images && images.length > 1 ? (
        <Arrows row>
          <Arrow
            onClick={(e) => e.stopPropagation() || galleryRef.current?.prev()}
          />
          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || galleryRef.current?.next()}
          />
        </Arrows>
      ) : null}

      <Counter>{`${currentSlide + 1}/${images.length}`}</Counter>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const Carousel = styled.div`
  display: flex;
  width: calc(100% - 9.375rem);
  height: calc(100% - 3.75rem);
  outline: none;
  overflow: hidden;
  position: absolute;
  top: 1.875rem;
  left: 1.875rem;

  @media (max-width: 1199px) {
    width: calc(100% - 6.9375rem);
    height: calc(100% - 2.625rem);
    top: 1.3125rem;
    left: 1.3125rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: calc(100% - 2.5rem);
    top: 0;
    left: 0;
  }
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const SiteName = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.0625rem;
  position: absolute;
  top: 6.5625rem;
  right: 3.375rem;
  transform-origin: bottom right;
  transform: rotate(-90deg);

  @media (max-width: 1199px) {
    font-size: 1.25rem;
    top: 5rem;
    right: 2.5rem;
  }
`

const Arrows = styled(FlexBox)`
  flex-direction: column;
  position: absolute;
  right: 1.8125rem;
  bottom: 7.5rem;

  > div {
    margin: 0;
    &:first-of-type {
      order: 2;
      margin-top: 0.9375rem;
    }
  }

  @media (max-width: 1199px) {
    right: 0.875rem;
  }

  @media (max-width: 1023px) {
    right: 1.1875rem;
  }

  @media (max-width: 767px) {
    flex-direction: row;
    right: auto;
    bottom: 4.375rem;
    left: 50%;
    transform: translateX(-50%);

    > div {
      border-color: ${({ theme }) => theme.colors.variants.neutralLight4};
      margin: 0 0.375rem;
      &:first-of-type {
        margin-top: 0;
        margin-left: 0.375rem;
      }
      &:last-of-type {
        order: 2;
      }

      svg {
        stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
      }
    }
  }
`

const Counter = styled.div`
  width: 7.5rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.0938rem;
  line-height: 1.125rem;
  padding: 1.3125rem 0;
  position: absolute;
  right: 0;
  bottom: 1.875rem;

  @media (max-width: 1199px) {
    width: 5.625rem;
    bottom: 1.3125rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    bottom: 0;
    padding: 0.6875rem 0;
  }
`
