import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Brand } from 'app/components/Icons'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

export interface Props {
  description?: string
  images?: ImageProps[]
  label?: string
  subtitle?: string
  title?: string
}

export const Staff = memo(function Staff({
  description,
  images,
  label,
  subtitle,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container id="section-staff">
      <Brand />
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {subtitle ? (
        <FadeInUp>
          <Subtitle>{subtitle}</Subtitle>
        </FadeInUp>
      ) : null}

      <Wrapper>
        {images && images[1] ? (
          <LazyLoadComponent>
            <Image
              alt={images[1].alt}
              sources={images[1].sources}
              src={images[1].src}
              srcSet={images[1].srcSet}
              width={images[1].width}
              height={images[1].height}
            />
          </LazyLoadComponent>
        ) : null}

        {label ? <Label>{label}</Label> : null}
      </Wrapper>

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}

      {images ? (
        <ParallaxProvider>
          <Parallax className="image-parallax image-0" translateY={[-50, 100]}>
            <LazyLoadComponent>
              <Image
                alt={images[0].alt}
                sources={images[0].sources}
                src={images[0].src}
                srcSet={images[0].srcSet}
                width={images[0].width}
                height={images[0].height}
              />
            </LazyLoadComponent>
          </Parallax>

          <Parallax className="image-parallax image-2" translateY={[0, -150]}>
            <LazyLoadComponent>
              <Image
                alt={images[2].alt}
                sources={images[2].sources}
                src={images[2].src}
                srcSet={images[2].srcSet}
                width={images[2].width}
                height={images[2].height}
              />
            </LazyLoadComponent>
          </Parallax>
        </ParallaxProvider>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  overflow: hidden;
  margin: 15.625rem 0 12.5rem;
  padding: 0 5.556vw 10.875rem;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  > svg {
    width: auto;
    height: 3.75rem;
    margin-bottom: 2.5rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .image-parallax {
    width: 11.25rem;
    height: 28.9vh;
    max-height: 16.25rem;
    position: absolute;
    top: 50%;
    left: 5.556vw;
    &.image-2 {
      right: 5.556vw;
      left: auto;
    }
  }

  @media (max-width: 1199px) {
    margin: 9.375rem 0 3.75rem;
    padding: 0 1.3125rem 5.625rem;

    > svg {
      margin-bottom: 1.875rem;
    }

    .image-parallax {
      left: 1.3125rem;
      &.image-2 {
        right: 1.3125rem;
      }
    }
  }

  @media (max-width: 1023px) {
    .image-parallax {
      display: none;
    }
  }

  @media (max-width: 767px) {
    margin-top: 5.625rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  line-height: 4.375rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.875rem;
  }
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: 0.0938rem;
  line-height: 1.125rem;
  margin-top: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    margin-top: 0.75rem;
  }
`

const Wrapper = styled.div`
  margin-top: 3.75rem;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 0.0625rem;
    height: 7.5rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    margin: auto;
  }

  picture {
    display: inline-block;
    width: 22.9375rem;
    height: 60vh;
    max-height: 33.75rem;
    margin: auto;
    position: relative;
  }

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
    &:before {
      height: 3.75rem;
    }
  }

  @media (max-width: 1023px) {
    picture {
      max-height: 28.125rem;
    }
  }

  @media (max-width: 767px) {
    &:after {
      content: '';
      width: 100%;
      height: 33%;
      background: linear-gradient(
        ${rgba(theme.colors.variants.neutralDark1, 0)},
        ${rgba(theme.colors.variants.neutralDark1, 0.4)}
      );
      position: absolute;
      bottom: 0.25rem;
      left: 0;
      z-index: 2;
    }

    picture {
      width: 100%;
      max-height: 21.875rem;
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 8.125rem;
  line-height: 10.875rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  @media (max-width: 1199px) {
    font-size: 5.625rem;
    line-height: 9.5625rem;
  }

  @media (max-width: 767px) {
    font-size: 2.5rem;
    line-height: 2.875rem;
    bottom: 1.875rem;
    z-index: 3;
  }
`

const Description = styled.div`
  max-width: 36rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.875rem;
  margin: 3.75rem auto 0;

  @media (max-width: 1023px) {
    max-width: none;
  }

  @media (max-width: 767px) {
    margin-top: 1.875rem;
  }
`
